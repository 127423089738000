const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    slotName: 'warehouseName',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'materialsCode',
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 320
  },
  {
    prop: 'materialsUnit',
    label: '库存单位',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 200
  },
  {
    prop: 'qualityInspectionPlanName',
    label: '质检方案',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  // {
  //   prop: 'qualityInspectionPlanType',
  //   label: '质检方案',
  //   slot: 'qualityInspectionPlanType',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'operator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  }
]

export default columns
