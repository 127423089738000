<!--流程引擎文件上传处-->
<template>
  <div class="container">
    <el-button type="primary" @click="modalShow = true;stepActive = 0" style="cursor: pointer">
      上传
      <i class="el-icon-upload el-icon--right"></i>
    </el-button>
    <div class="tip"> * * * * 此处上传流程文件，请勿随意操作 * * * * </div>

    <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
      >
        <div slot="action" slot-scope="{ row }">
          <el-button type="text" @click="downLoad(row)">下载</el-button>
        </div>
      </MTable>

    <MDialog v-model="modalShow" title="上传流程文件" width="80%">
      <!-- <div @click="showLog">showLog</div> -->
      <div class="batchImport">
        <el-steps :active="stepActive" finish-status="success" process-status="finish">
          <el-step title="上传文件" description="请上传指定格式文件" />
          <el-step title="上传成功" description="已保存文件" />
        </el-steps>
        <!-- 上传文件 -->
        <div>
          <div class="down-template">
            <div class="down-le" />
            <div class="down-ri">
              <div class="down-des">上传文件格式仅支持xml，且文件大小不得超过20M</div>
              <div class="down-btn">
                <el-upload
                  ref="upload"
                  :file-list="fileList"
                  v-bind="uploadProps"
                  :on-success="handleAvatarSuccess"
                  :on-remove="fileRemove"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="handleExceed"
                >
                  <el-button
                    type="text"
                    class="icons"
                    icon="el-icon-upload"
                    style="padding: 0"
                  >上传文件</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="mubai-dialog-footer center-content">
        <el-button @click="cancel">{{btnName}}</el-button>
      </div>
    </MDialog>
  </div>
</template>
<script>
import XLSX from 'xlsx'
import api from '@/api/columnsSetApi'
import { getToken } from '@/utils/auth'
import conventionMixin from '@/mixin/conventional-page'
export default {
  name: 'SimpleImport',
  mixins: [conventionMixin],
  props: {

  },
  data() {
    return {
      api,
      btnName:'取消',
      modalShow: false,
      stepActive: 0,
      fileList: [],
      params: {
          radio: 'Abnormal'
        },
        functionHeader:{
          searchVal:''
        },
      searchKeyword: true,
      uploadProps: {
        multiple: false,
        accept: '.xml',
        // action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/flowable/private/flowable/deployment`,
        headers: { token: getToken() },
        showFileList: true,
        limit: 1
      },
    columns : [
      {
        prop: 'processName',
        label: '名称',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'name',
        label: '文件名称',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'deploymentDate',
        label: '上传时间',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'version',
        label: '版本',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        sortable: false,
        hiddenSearch: true
      }
    ]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 90
    },
    loadingHeight() {
      return 270
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制只能上传 1 个文件`);
    },
    cancel(){
      this.modalShow = false
      this.stepActive= 0
      this.btnName = '取消'
      this.fileList = []
    },
    showLog(){
      consoleSelf.info("fileList",this.fileList);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 20MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.fileList.push(res.result)
        consoleSelf.info("上传成功",this.fileList[0]);
        this.$message.success('上传成功')
        this.$refs.mTable.setTableData()
        this.stepActive = 1
        this.btnName = '确定'
      } else {
        this.$message.error(res.result)
      }
    },
    fileRemove() {
      this.btnName = '取消'
      this.stepActive = 0
      this.fileList = []
    },

    async downLoad(row){
      const res = await api.getDeploymentBpmnXml({
        deploymentId:row.deploymentId
      })
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream;charset=ISO8859-1' }))
        const fileName = `${row.name}-${row.version}.xml`
        this.downLoadFile(urlP,fileName)
        } else {
        this.$message.error('下载失败')
        }
    },
    downLoadFile(urlP,fileName) {
      const link = document.createElement('a')
          link.style.display = 'none'
          link.href = urlP
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
    },
  }
}
</script>

<style lang="scss" scoped>
.tip{
  padding: 12px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
  font-size: 13px;
  color: red;
  font-weight: bold;
}
.batchImport {
  padding: 10px;

  .down-template {
    background: #f6f7f9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607fff;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9fa1b7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607fff;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607fff;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607fff;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607fff;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091ff;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00ab29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a9abbf;
        line-height: 22px;
      }
    }
  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393d60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #dde3eb;
  padding: 10px 20px;
  bottom: 0;
  background: #ffffff;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #f8f8fb;
      color: #8a8ca5;
      transition: all 0.5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all 0.5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep .has-error {
  background: rgba(224, 32, 32, 0.04);
}
.warning{
  border: 1px solid red;
  border-radius: 4px;
}
</style>
