<template>
  <div class="inventory-account-container">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        @search="searchTable"
      >
        <SelectMultipleMaterialsNew slot="materialsM" ref="multipleMaterial" @selectChange="materialsChange" />
      </SearchForm>
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入申请单号"
        :tags="!selectData.length ? functionHeader.tags : []"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export = "!selectData.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
      <div slot="center" v-show="selectData.length" >
        <el-button v-if="permission('ConfirmOrUnConfirm')" type="primary" size="small" @click="more({}, 'batchConfirm')">批量确认申请</el-button>
        <el-button v-if="permission('ConfirmOrUnConfirm')" type="primary" size="small" @click="more({}, 'batchUnConfirm')">批量反确认</el-button>
        <el-button v-if="permission('ConfirmOrUnConfirm')" type="primary" size="small" @click="more({}, 'batchCancel')">批量驳回</el-button>
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="more({}, 'batchExport')">批量导出</el-button>
        <el-button v-if="permission('Del')" type="primary" size="small" @click="more({}, 'batchDel')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="status" slot-scope="{ row }">{{ row.status | statusName }}</div>
        <div slot="action" slot-scope="{ row }">
          <!-- <el-button v-if="row.status === 0 && permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <a v-else style="color:#c4c4c4;">修改</a>
          <el-divider direction="vertical" />
          <el-dropdown v-if="row.status !== 2" size="small" @command="(command) => { more(row, command) }">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item v-if="permission('ConfirmOrUnConfirm')" command="confirmOrUnConfirm">{{ row.status ? '反确认申请' : '确认申请' }}</el-dropdown-item>
              <el-dropdown-item v-if="row.status === 0 && permission('ConfirmOrUnConfirm')" command="cancel">驳回</el-dropdown-item>
              <el-dropdown-item v-if="!row.status && permission('Del')" command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a v-else style="color:#c4c4c4;">更多<i class="el-icon-arrow-down el-icon--right" /></a> -->

          <!-- 新的 ————————Strat———————— -->
          <el-button v-if="permission('ConfirmOrUnConfirm')" type="text" @click="confirmOrUnConfirm(row, 'confirmOrUnConfirm')">
            {{ row.status ? '反确认申请' : '确认申请' }}
          </el-button>
          <el-divider v-if="permission('ConfirmOrUnConfirm')" direction="vertical" />

          <el-button v-if="row.status === 0 && permission('ConfirmOrUnConfirm')" type="text" 
          @click="cancelRequest(row, 'cancel')">
          驳回
          </el-button>
          <el-divider v-if="row.status === 0 && permission('ConfirmOrUnConfirm')" direction="vertical" />

          <el-button v-if="row.status === 0 && permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <a v-else style="color:#c4c4c4;">修改</a>
          <el-divider v-if="!row.status && permission('Del')" direction="vertical" />

          <el-button v-if="!row.status && permission('Del')" type="text" 
          @click="del(row)">
          删除
          </el-button>
          <!-- 新的 ————————End———————— -->


        </div>
      </MTable>
    </div>

    <CURDDialog :visible.sync="visible" :data="formData" @submit="submitDialog" />
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { searchFormList } from './form-list'
import api from '@/api/warehouse/purchase'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { inType, outType } from '@/config/options.config'
import userApi from '@/api/sets/user/user'
import warehouseApi from '@/api/information/warehouse/model'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { export_json_to_excel } from '@/utils/plug/to-excel'
import CURDDialog from './CURD-dialog.vue'

export default {
  name: 'Purchase',
  components: { SelectMultipleMaterialsNew, SearchForm, FunctionHeader, CURDDialog },
  filters: {
    statusName(val) {
      switch (val) {
        case 0: return '待确认'
        case 1: return '已确认'
        default: return '已驳回'
      }
    }
  },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Purchase'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新建申请',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportPurchase',
        exportParams: {}
      },
      inOrOutTypeList: [outType, inType],
      inOrOutType: '',
      type: 0,
      selectData: [],
      visible: false,
      formData: {},
      searchFieldName: 'requestNumber',
      extraParams: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 62
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getUserList()
    this.getWarehouseList()
  },
  methods: {
    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[2].children[0].options = res
        this.searchFormList[3].children[0].options = res
      }
    },
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify([...this.defaultSearch, {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    modifyOrCopy(row, command) {
      this.formData = {
        id: row.id,
        materialsId: row.materialsId,
        materialsCode: row.materialsCode,
        materialsName: row.materialsName,
        materialsSpecification: row.materialsSpecification,
        materialsUnit: row.materialsUnit,
        availableQuantity: row.availableQuantityNow,
        currentQuantity: row.currentQuantityNow,
        requiredTime: row.requiredTime,
        quantity: row.quantity,
        remark: row.remark
      }
      this.currentType = command
      this.visible = true
    },
    materialsChange(key, val) {
      this.extraParams = []
      if (val) {
        this.extraParams.push({
          id: getUUid(),
          fieldName: 'materialsId',
          fieldType: 'number',
          fieldValue: val.id,
          operator: 'eq'
        })
      }
      this.$refs.mTable.setTableData(true)
    },
    selectionChange(data) {
      this.selectData = data
    },
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    searchTable(data, val) {
      this.searchFormatData = data
      if (val === 1) {
        this.$refs.multipleMaterial.keyword = ''
        this.extraParams = []
      }
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作
    more(row, command) {
      const functions = {
        confirmOrUnConfirm: () => {
          this.confirmOrUnConfirm(row, command)
        },
        cancel: () => {
          this.cancelRequest(row, command)
        },
        batchCancel: () => {
          const validate = this.selectData.every(item => !item.status && item.status !== 2)
          if (!validate) {
            return this.$message.info('选中数据中包含已确认或已驳回数据')
          }
          this.cancelRequest()
        },
        del: () => {
          this.del(row)
        },
        batchDel: () => {
          const validate = this.selectData.every(item => !item.status && item.status !== 2)
          if (!validate) {
            return this.$message.info('选中数据中包含已确认或已驳回数据')
          }
          this.delBatch()
        },
        batchConfirm: () => {
          const validate = this.selectData.every(item => !item.status && item.status !== 2)
          if (!validate) {
            return this.$message.info('选中数据中包含已确认或已驳回数据')
          }
          this.confirmOrUnConfirm({ status: 0 })
        },
        batchUnConfirm: () => {
          const validate = this.selectData.every(item => item.status && item.status !== 2)
          if (!validate) {
            return this.$message.info('选中数据中包含未确认或已驳回数据')
          }
          this.confirmOrUnConfirm({ status: 1 })
        },
        batchExport: () => { this.batchExportFront() }
      }
      functions[command]()
    },
    batchExportFront() {
      this.$refs.functionHeader.export({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectData.map(item => item.id).join('^'),
          operator: 'in'
        }]))
      })
    },
    delBatch() {
      this.currentType = 'del'
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delBatchPurchase({ ids: this.selectData.map(item => item.id).join('^') })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    confirmOrUnConfirm(row, command) {
      const confirmOrUnConfirmData = command ? [row] : this.selectData
      this.$confirm(`您确定要${row.status ? '反确认申请' : '确认申请'}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api[`${row.status ? 'unConfirm' : 'confirm'}Purchase`]({ ids: confirmOrUnConfirmData.map(item => item.id).join('^') })
        if (res) {
          this.$message.success(`${row.status ? '反确认申请' : '确认申请'}成功`)
          this.$refs.mTable.setTableData()
        }
      })
    },
    cancelRequest(row, command) {
      const cancelData = command ? [row] : this.selectData
      this.$confirm('您确定要驳回申请吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelPurchase({ ids: cancelData.map(item => item.id).join('^') })
        if (res) {
          this.$message.success('驳回成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    batchExport() {
      const params = {
        key: this.columns.map(item => item.prop),
        header: this.columns.map(item => item.label),
        content: this.selectData,
        merges: [],
        data: this.tableData,
        filename: '错误数据'
      }
      export_json_to_excel(params)
    },
    submitDialog() {
      this.$refs.mTable.setTableData()
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-account-container {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep .customer-content div {
  width: 100%;
}
::v-deep .customer-content2 div:first-child {
  display:block !important;
}
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
</style>
