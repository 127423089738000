<template>
  <div class="scrap-wrap">
    <div class="top">
      <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入物料编码/名称/规格/批次码"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!exportSelectionIds.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length" style="margin-left:10px;">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ exportSelectionIds.length }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="warehouseName">废品仓</div>
        <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        <div slot="qualityInspectionPlanType" slot-scope="{ row }">{{ checkTypeName[row.qualityInspectionPlanType] }}</div>
      </MTable>
    </div>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import searchFormList from './form-list'
import columns from './columns'
import { getUUid } from '@/utils'
import maApi from '@/api/information/materials-attribute'
import api from '@/api/warehouse/scrap'
import { Encrypt } from '@/utils/sercet'
import { checkType } from '@/config/options.config'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import batchExportFront from '@/mixin/batch-export-front'

export default {
  name: 'Scrap',
  components: { EditMaterials, SearchForm, FunctionHeader },
  mixins: [viewMaterialsDetail, batchExportFront],
  data() {
    return {
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        exportName: 'exportScrap',
        exportParams: {}
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      checkTypeName: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 35
    }
  },
  beforeMount() {
    this.searchFormList[3].props.pickerOptions = this.pickerOptions
  },
  mounted() {
    this.getMaterialsTypeList()
    checkType.forEach(item => {
      this.checkTypeName[item.id] = item.name
    })
  },
  methods: {
    resetSelectData(){
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList(this.defaultSearch)
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await api.getWastListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
  margin-left: -10px;
}
.scrap-wrap {
  width: 100%;
  .bottom {
    padding: 10px;
  }
}
</style>
