<template>
  <div>
    <MDialog v-model="modalShow" width="70%" :title="messages[currentType]" @onOk="submitForm">
      <el-button v-if="currentType === 'add'" type="primary" style="margin-bottom: 15px" @click="openMaterials('inventory')">从库存选择物料</el-button>
      <el-button v-if="currentType === 'add'" type="primary" style="margin-bottom: 15px" @click="openMaterials('materials')">添加物料</el-button>
      <MTable ref="mTable" :columns="CURDColumns" :height="height" :data="purchaseData" :show-page="false">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="quantity" slot-scope="{ row }">
          <el-input-number v-model="row.quantity" :min="0.1" controls-position="right" :step="1" style="width: 100%" />
        </div>
        <div slot="requiredTime" slot-scope="{ row }">
          <el-date-picker v-model="row.requiredTime" placeholder="请选择日期" type="date" style="width: 100%" />
        </div>
        <div slot="remark" slot-scope="{ row }">
          <el-input v-model="row.remark" type="textarea" style="width: 100%" />
        </div>
        <div slot="action" slot-scope="{ $index }">
          <el-button type="text" @click="deleteRow($index)">删除</el-button>
        </div>
      </MTable>
    </MDialog>
    <select-materials :visible.sync="showMaterials" :select-materials="selectMaterials" :from="from" @submitForm="submitMaterials" />
  </div>
</template>

<script>
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import api from '@/api/warehouse/purchase'
import { CURDColumns } from './columns'
import conventionMixin from '@/mixin/conventional-page'
import dayjs from 'dayjs'

export default {
  name: 'CURDDialog',
  components: { SelectMaterials },
  mixins: [conventionMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      api,
      params: {
        radio: 'Purchase'
      },
      CURDColumns,
      purchaseData: [],
      showMaterials: false,
      modalShow: false,
      currentType: 'add',
      from: 'materials',
      selectMaterials: [],
      materialsList: [],
      inventoryMaterialsList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 55
    }
  },
  watch: {
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', val)
      }
    },
    visible(val) {
      if (val) {
        this.currentType = 'add'
        this.CURDColumns = CURDColumns
        if (Object.keys(this.data).length) {
          const cloneData = this._.cloneDeep(this.data)
          this.purchaseData = [cloneData]
          this.currentType = 'modify'
          const newColumns = this._.cloneDeep(CURDColumns)
          newColumns.pop()
          this.CURDColumns = newColumns
        } else {
          this.purchaseData = []
        }
        this.modalShow = true
      }
    }
  },
  methods: {
    openMaterials(type) {
      this.from = type
      if (type === 'materials') {
        this.selectMaterials = this.materialsList
      }
      if (type === 'inventory') {
        this.selectMaterials = this.inventoryMaterialsList
      }
      this.showMaterials = true
    },
    async submitMaterials(data) {
      const newData = [...data, ...this.purchaseData]
      const res = await api.getMaterialsInventoryList({
        materialsIds: Array.from(new Set(newData.map(item => item.materialsId || item.id))).join('^')
      })
      if (res) {
        const newArr = []
        const ids = this.purchaseData.map(item => item.id)
        res.forEach(item => {
          const index = ids.indexOf(item.materialsId)
          if (index !== -1) {
            newArr.push(this.purchaseData[index])
          } else {
            newArr.push(this.formatItem(item))
          }
        })
        if (this.from === 'materials') {
          this.materialsList = data
        } else {
          this.inventoryMaterialsList = data
        }
        this.purchaseData = newArr
        this.showMaterials = false
      }
    },
    formatItem(item) {
      return {
        materialsId: item.materialsId,
        materialsCode: item.materialsCode,
        materialsName: item.materialsName,
        materialsSpecification: item.materialsSpecifications,
        materialsUnit: item.materialsUnit,
        availableQuantity: item.availableQuantity,
        currentQuantity: item.currentQuantity
      }
    },
    deleteRow(index) {
      this.purchaseData.splice(index, 1)
    },
    async submitForm(callback) {
      const validate = this.purchaseData.every(item => item.quantity && item.requiredTime)
      if (validate) {
        let res
        if (this.currentType === 'add') {
          res = await api.addPurchase(this.createSetData())
        } else {
          res = await api.modifyPurchase(this.createResetData(this.purchaseData[0]))
        }
        if (res) {
          this.$emit('submit')
          this.$message.success(this.getSuccessMessage())
          this.modalShow = false
        }
        callback(false)
      } else {
        callback(false)
        this.$message.info('请填写所有数量和需求日期')
      }
    },
    createSetData() {
      return {
        requestDetailList: this.purchaseData.map(item => ({
          materialsId: item.materialsId,
          materialsName: item.materialsName,
          materialsSpecification: item.materialsSpecifications,
          materialsUnit: item.materialsUnit,
          quantity: item.quantity,
          remark: item.remark,
          currentQuantity: item.currentQuantity,
          availableQuantity: item.availableQuantity,
          requiredTime: dayjs(item.requiredTime).format('YYYY-MM-DD')
        }))
      }
    },
    createResetData(row) {
      return {
        id: row.id,
        requestId: row.requestId,
        requestNumber: row.requestNumber,
        materialsId: row.materialsId,
        quantity: row.quantity,
        remark: row.remark
      }
    }
  }
}
</script>

<style scoped>

</style>
