export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '申请状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'requestNumber',
    label: '采购申请单号',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsSpecification',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 240
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '申请数量',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'requiredTime',
    label: '需求日期',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'currentQuantity',
    label: '申请时当时存货',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'availableQuantity',
    label: '申请时可用库存',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'currentQuantityNow',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'availableQuantityNow',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '制单人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '制单时间',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'confirmUser',
    label: '处理人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'confirmTime',
    label: '处理时间',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    width: 230,
    hiddenSearch: true,
    sortable: false
  }
]

export const CURDColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecification',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right',
    width: 150,
    slotName: 'quantity'
  },
  {
    prop: 'requiredTime',
    label: '需求日期',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right',
    width: 160,
    slotName: 'requiredTime'
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    slotName: 'remark'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    width: 100,
    hiddenSearch: true,
    sortable: false
  }
]
