<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="demo-form-inline"
        >
          <!-- 详情 -->
          <el-row>
            <el-col v-for="item in detailReturnBaseFields" :key="item.key" :span="6">
              <el-form-item :label="item.name">
                <div>{{ getDetailVal(item.key) }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card" style="padding:10px">
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入物料名称/编号/规格"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable
          ref="mTable"
          :columns="applyReturnColumn"
          :height="410"
          :set-data-method="getTableData"
          :columns-setting="false"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="materialsType" slot-scope="{row }">
            {{ row.materialsType }}
          </div>
          <div slot="planOutputQuantity" slot-scope="{ row }">
            <el-input-number v-model="row.lockQuantity" :min="0" :max="row.maxLockQuantity" controls-position="right"
                             :step="1" style="width: 90%"></el-input-number>
          </div>
          <template slot="planTime" slot-scope="{ row }">
            <div>{{ row.planTime.join(' 至 ') }}</div>
          </template>

          <template slot="action" slot-scope="{ $index }">
            <el-button type="text" @click="del($index)">删除</el-button>
          </template>

        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div/>
      <div class="footer-ri">
        <el-button @click="returnList">返 回</el-button>
      </div>
    </div>
    <div class="lines"/>

    <MDialog v-model="visiable" :title="currentType" @onOk="addOrEditSubmit" confirmButtonText="入库">
      <MFormBuilder ref="formBuilds" :form-data="dataForAdd" :form-list="formList">
        <div slot="warehouseLocationName" style="display: flex;align-items: center;justify-content: flex-start;">
          <template>
            <span style="color: #f00;width: 10px;">*</span>
            <el-cascader
              style="width: 100%;"
              v-model="kwId"
              :options="warehouseLocationList"
              :props="{value: 'id', label: 'name'}"
            />
          </template>
        </div>
      </MFormBuilder>
    </MDialog>

  </div>
</template>
<script>
import api from '@/api/warehouse/warehouse-assign'
import sitApi from '@/api/information/warehouse/model'
import { detailReturnBaseFields, applyReturnColumn } from '../columns'
import { base } from '@/views/information/production/process/detail/columns'
import { materialsRequisitionStatus, docType } from '@/config/options.config'
import FunctionHeader from '@/components/FunctionHeader/index'
import dayjs from 'dayjs'
import warehouseApi from '@/api/information/warehouse/model'
import { setTreeData } from '@/utils'

export default {
  name: 'ReturnAssignDetail',
  components: { FunctionHeader },
  data() {
    return {
      visiable: false,
      kwId:'',
      isLocation:false,
      dataForAdd: {},
      allWarehouseList: [],
      warehouseLocationList: [],
      formList: [
        {
          label: '仓库',
          colSpan: 12,
          key: 'wouseId',
          tagName: 'el-select',
          props: {
            placeholder: '请选择仓库',
            clearable:false,
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ],
          on: {
            change: async (val) => {
              const isLocation = this.allWarehouseList.filter(item => item.id === val)[0].isLocation
              consoleSelf.info('isLocation', isLocation)
              this.kwId = ''
              if (+isLocation === 1) {
                this.isLocation = true
                await this.getAllNodesByWarehouseId(val)
                this.formList[1].hidden = false
              }else {
                this.isLocation = false
                this.warehouseLocationList = []
                this.formList[1].hidden = true
              }
            }
          }
        },
        {
          label: '库位',
          colSpan: 12,
          hidden: true,
          key: 'warehouseLocationId',
          tagName: 'div',
          children: [
            {
              customSlots: ['warehouseLocationName']
            }
          ]
        }
      ],
      currentType: '选择仓库',
      api,
      logVisible: false,
      orderLists: [],
      projectList: [],
      docType,
      ruleForm: {
        code: '',
        name: '',
        receiptId: undefined,
        receiptType: 0,
        receiptNumber: '',
        receiptName: '',
        projectNumber: '',
        projectName: '',
        remark: '',
        projectId: undefined,
        requireDate: dayjs(new Date()).format('YYYY-MM-DD 00:00:00')
      },
      rules: {
        code: [
          { required: true, message: '请输入退料单号', trigger: 'blur' }
        ],
        requireDate: [
          { required: true, message: '请选择退料时间', trigger: 'blur' }
        ],
        receiptType: [
          { required: true, message: '请选择单据类型', trigger: 'blur' }
        ],
        receiptNumber: [
          { required: true, message: '请选择单据单号', trigger: 'blur' }
        ]
      },
      baseFormData: {},
      columnsBase: base,
      detailReturnBaseFields,
      materialsList: [],
      applyReturnColumn,
      functionHeader: {
        searchVal: '',
        importName: '',
        exportName: '',
        exportParams: {},
        tags: [
          {
            hidden: true,
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '入库',
            on: {
              click: this.inWarehouse
            }
          }
        ]
      },
      selectMaterials: [],
      searchId: undefined
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'materialsApplyList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  async created() {
    this.type = this.$route.query.type
    await this.getRepositoryModelList()
    await this.getDetail()
  },
  mounted() {
  },
  methods: {
    async getAllNodesByWarehouseId(id) {
      const res = await warehouseApi.getAllNodesByWarehouseId(id)
      if (res) {
        this.warehouseLocationList = res.length > 1 ? setTreeData(res) : []
      }
    },
    inWarehouse() {
      this.kwId = ''
      this.warehouseLocationList = []
      this.formList[1].hidden = true
      this.visiable = true
      this.dataForAdd = {}
    },
    async addOrEditSubmit(callback) {
      const { form } = this.$refs.formBuilds
      if(!form.wouseId){
        this.$message.error('请选择仓库')
        callback()
        return
      }else {
        if(this.isLocation){
          if (!this.kwId) {
            this.$message.error('请选择库位')
            callback()
            return
          }
        }
      }
      const that = this
      const res = await api.outInWarehouseRequisitionTask({
        taskId: this.$route.query.id,
        outInWarehouseType: 0,
        warehouseId: form.wouseId,
        warehouseLocationId:that.kwId ? that.kwId[that.kwId.length - 1] : ''
      })
      if (res) {
        this.$message.success('入库成功')
        callback(true)
        this.visiable = false
        this.getDetail()
        this.$refs.mTable.setTableData()
      }
    },
    // 获取仓库列表
    async getRepositoryModelList() {
      const res = await sitApi.getRepositoryModelList()
      this.allWarehouseList = res
      if (res) {
        this.formList[0].children[0].options = res.filter(item => item.type === 0)
      }
    },
    // 获取详情物料表
    async getTableData(condition, callback) {
      const { page } = condition
      const searchCondition = {
        search: this.functionHeader.searchVal,
        id: this.$route.query.id
      }
      this.functionHeader.exportParams = searchCondition
      const res = await api.getMaterialsRequisitionTaskListPage({
        page: page.currentPage,
        size: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 获取退料单基本信息值
    getDetailVal(key) {
      const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key] : '-'
      if (key === 'receiptType') {
        const current = docType.find(item => item.id === result)
        return current && current.name
      } else if (key === 'receiptStatus') {
        const current = materialsRequisitionStatus.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    // 获取详情
    async getDetail() {
      const res = await api.getRequisitionTaskDetail({ id: this.$route.query.id })
      if (res) {
        this.ruleForm = {
          ...res
        }
        this.functionHeader.tags[0].hidden = this.ruleForm.receiptStatus != 0 ? true : false
      }
    },

    returnList() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;

    .el-form-item__label {
      padding-right: 0;
    }
  }
}

::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }

  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
}

.orderAdd {
  width: 100%;
  height: 100%;

  .input-new-tag {
    width: 130px;
  }

  .button-new-tag {
    height: 32px;
  }

  .detail-card {
    margin-bottom: 20px;
  }

  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  .e-btn {
    margin-bottom: 10px;
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
