
const searchFormList = [
  {
    key: 'materialsType',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择物料类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 5,
    key: 'materialsName',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入物料名称'
    },
    children: []
  },
  {
    colSpan: 5,
    key: 'qualityInspectionPlanName',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入质检方案'
    },
    children: []
  },
  {
    colSpan: 5,
    key: 'operateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    },
    children: []
  }
]

export default searchFormList
