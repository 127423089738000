import { warehouseRecordTypeAll } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const searchFormList = [
  {
    key: 'warehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择仓库'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'recordType',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '流水方向'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{
          id: 0,
          name: '流入'
        }, {
          id: 1,
          name: '流出'
        }]
      }
    ],
    on: {
      change: (val) => { functionList.onRecordTypeChange(val) }
    }
  },
  {
    key: 'type',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '库存操作类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content2' },
    children: [
      {
        slots: ['materialsM']
      }
    ]
  },
  {
    key: 'materialsType',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择物料类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    key: 'operator',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择创建人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'operateTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始时间',
      endPlaceholder: '创建结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]
