<template>
  <div class="inventory-wrap common-tab">
    <el-radio-group
      v-model="params.radio"
      class="m-radio-group"
      @change="radioChange"
    >
      <el-radio-button label="InventoryDetail">库存明细</el-radio-button>
      <el-radio-button label="Inventory">仓库汇总</el-radio-button>
      <el-radio-button label="Materials">物料汇总</el-radio-button>
      <el-radio-button label="Batch">批次码汇总</el-radio-button>
    </el-radio-group>
    <div class="bottom">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
          <MaterialsClass
            :view="true"
            @setMaterialsClass="setMaterialsClass"
            @nodeClick="setMaterialsClassId"
          />
        </template>
        <template slot="right">
          <div class="materials-info">
            <div v-show="searchFormList[params.radio]">
              <SearchForm
                ref="searchForm"
                :form-list-extend="searchFormList[params.radio]"
                :form-data="searchFormData[params.radio]"
                :default-form="defaultSearchFormData[params.radio]"
                @search="searchTable"
              />
            </div>
            <div class="bottom">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                :search-title="functionHeader.searchTitle[params.radio]"
                :radio="params.radio"
                :columns-key="columnsKey"
                :export-name="functionHeader.exportName"
                :export-params="functionHeader.exportParams"
                :show-export="!selectTableData.length ? true : false"
                :columns="columns[params.radio]"
                @search="$refs.mTable.setTableData(true)"
              >
                <el-button
                  v-if="permission(params.radio + 'InventoryHistory') && !selectTableData.length"
                  type="primary"
                  size="small"
                  @click="inWarehouse"
                >
                  入库
                </el-button>
                <el-button
                  v-if="permission(params.radio + 'InventoryHistory') && !selectTableData.length"
                  type="primary"
                  size="small"
                  @click="outWarehouse"
                >
                  出库
                </el-button>

                <el-button
                  v-if="!selectTableData.length"
                  type="primary"
                  size="small"
                  @click="allOperate(2)"
                >
                调拨
                </el-button>

                <el-button
                  v-if="!selectTableData.length"
                  type="primary"
                  size="small"
                  @click="allOperate(3)"
                >
                移库
                </el-button>

                <el-button
                  v-if="!selectTableData.length"
                  type="primary"
                  size="small"
                  @click="allOperate(4)"
                >
                仓库盘点
                </el-button>

                <el-button
                  v-if="!selectTableData.length"
                  type="primary"
                  size="small"
                  @click="allOperate(5)"
                >
                物料盘点
                </el-button>

                <el-button
                  type="primary"
                  size="small"
                  v-if="selectTableData.length"
                  @click="batchExportFront"
                >
                  批量导出
                </el-button>
                <span v-if="selectTableData.length" style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;
                line-height:32px;">
                  已选 
                  <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                    / {{ total }}项
                </span>
                <el-button type="text" v-if="selectTableData.length" size="small" @click="resetSelectData">清空</el-button>

                <div
                  slot="all"
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  "
                >

                  <!-- <el-dropdown
                    size="small"
                    split-button
                    class="all-operator"
                    @command="allOperate"
                  >
                    <span class="el-dropdown-link"> 其他操作 </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="2">调拨</el-dropdown-item>
                      <el-dropdown-item command="3">移库</el-dropdown-item>
                      <el-dropdown-item command="4">仓库盘点</el-dropdown-item>
                      <el-dropdown-item command="5">物料盘点</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                </div>
              </FunctionHeader>
              <MTable
                ref="mTable"
                :columns="columns[params.radio]"
                :height="height"
                :set-data-method="getTableData"
                :columns-key="columnsKey"
                @selection-change="handleSelectionChange"
              >
                <div
                  :slot="checkNumberKey"
                  slot-scope="{ row }"
                  style="width: 100%; display: inline-flex; align-items: center"
                >
                  <div style="display: inline-block">
                    {{ row[`${checkNumberKey}2`] || row[checkNumberKey] }}
                  </div>
                  <i
                    v-if="
                      row.lowerLimit && row[checkNumberKey] < row.lowerLimit
                    "
                    class="icon-lowerLimit"
                  />
                  <i
                    v-if="
                      row.upperLimit && row[checkNumberKey] > row.upperLimit
                    "
                    class="icon-upperLimit"
                  />
                  <!-- <i v-if="row.isLimit && row[checkNumberKey] > row.upperLimit" class="icon-upperLimit" /> -->
                  <!-- <div v-if="row.isLimit && checkNumber(row)" class="tooltip">{{ checkNumber(row) }}</div> -->
                </div>
                <NewPageOpen
                  slot="materialsCode"
                  slot-scope="{ row }"
                  :path="{
                    name: 'materialsDetail',
                    query: { id: row.materialsId, type: 'view' }
                  }"
                  :text="row.materialsCode"
                />
                <div slot="unitName" slot-scope="{ row }">
                  <div v-if="!row.materialsUnitList">
                    {{ row.materialsUnit }}
                  </div>
                  <el-select
                    v-else
                    :value="row.materialsUnit"
                    @change="
                      id => {
                        currentUnitChange(id, row);
                      }
                    "
                  >
                    <el-option
                      v-for="(item, index) in getUnits(row)"
                      :key="index"
                      :value="item.name"
                    >
                      <span v-if="item.main" style="color: #df6a6a">
                        {{ item.name }}
                        <sup style="color: #df6a6a">*</sup>
                      </span>
                      <span v-else>{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div slot="warehouseName" slot-scope="{ row }">
                  {{ row.warehouseName }}
                </div>
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <!-- <div slot="lowerLimit" slot-scope="{ row }">
                {{ row.isLimit ? row.lowerLimit : "" }}
              </div> -->
                <div slot="lowerLimit" slot-scope="{ row }">
                  {{ row.lowerLimit ? row.lowerLimit : "-" }}
                </div>
                <div slot="upperLimit" slot-scope="{ row }">
                  {{ row.upperLimit ? row.upperLimit : "-" }}
                </div>
                <div slot="warehouseLocation" slot-scope="{ row }">
                  {{ row.warehouseLocationName }}
                </div>
                <div :slot="getOtherKey()" slot-scope="{ row }">
                  {{ row[`${getOtherKey()}2`] || row[getOtherKey()] }}
                </div>
                <a
                  slot="otherNumber"
                  slot-scope="{ row }"
                  @click="viewNumberDetails(row, 'viewOtherDetail', 1)"
                >
                  {{ row.scatterQuantity }}
                </a>
                <a
                  slot="packageNumber"
                  slot-scope="{ row }"
                  @click="viewNumberDetails(row, 'viewPackageDetail')"
                >
                  {{ row.containerQuantity }}
                </a>
                <div slot="action" slot-scope="{ row }">
                  <a @click="viewDetails(row)">
                    查看明细
                  </a>
                </div>
              </MTable>
            </div>
          </div>
        </template>
      </Split>
    </div>

    <MDialog
      v-model="visible"
      :title="dialogTitle[dialogType]"
      @onOk="submitForm"
    >
      <div
        v-if="
          ['viewOtherDetail', 'viewPackageDetail'].indexOf(dialogType) !== -1
        "
        class="detail-wrapper"
      >
        <el-row :gutter="20" style="padding-bottom: 20px">
          <el-col
            :span="10"
            style="font-size: 16px"
          >物料名称: {{ currentDialogData.materialsName }}</el-col>
          <el-col
            :span="10"
            style="font-size: 16px"
          >物料规格: {{ currentDialogData.materialsSpecifications }}</el-col>
          <el-col
            :span="4"
            style="font-size: 16px"
          >数量:
            {{
              dialogType === "viewPackageDetail"
                ? currentDialogData.containerQuantity
                : currentDialogData.scatterQuantity
            }}
            {{ isUnit === 1 ? currentDialogData.materialsUnit : "" }}
          </el-col>
        </el-row>
        <FunctionHeader
          v-model="functionHeader2.searchVal"
          :search-title="
            dialogType === 'viewOtherDetail'
              ? '请输入批次码'
              : '请输入包装码/批次码'
          "
          :columns-setting="false"
          @search="searchFromPackage"
        >
          <el-button
            size="small"
            type="primary"
            :disabled="!selectTableDataDialog.length"
            @click="transferLocation"
          >
            库存转移
          </el-button>
        </FunctionHeader>
        <MTable
          :show-page="false"
          :data="packageDetailData"
          :columns="columns[dialogType]"
          :columns-setting="false"
          :height="dialogTableHeight"
          @selection-change="handleSelectionChangeDialog"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="warehouseLocation" slot-scope="{ row }">
            {{ row.warehouseAreaName }} - {{ row.warehouseLocationName }}
          </div>
          <div
            v-if="JSON.parse(row.containerInfo)"
            slot="container"
            slot-scope="{ row }"
          >
            {{ JSON.parse(row.containerInfo).name }}
            （{{ JSON.parse(row.containerInfo).specification }}）
          </div>
          <div slot="batchNumberList" slot-scope="{ row }">
            {{ row.batchNumberList && row.batchNumberList.join("，") }}
          </div>
          <div slot="action" slot-scope="{ row }">
            <el-button
              type="text"
              @click="changePackage(row, 'changePackage')"
            >更换包装</el-button>
            <el-button type="text" @click="unpacking(row)">拆包</el-button>
            <el-button
              type="text"
              @click="printPackageCode(row)"
            >补打包装箱码</el-button>
          </div>
        </MTable>
      </div>
      <div v-if="['viewInventoryHistory'].indexOf(dialogType) !== -1">
        <el-row :gutter="20" style="padding-bottom: 20px">
          <el-col
            :span="6"
            style="font-size: 16px"
          >仓库名称: {{ currentDialogData.warehouseName }}</el-col>
          <el-col
            :span="6"
            style="font-size: 16px"
          >物料名称: {{ currentDialogData.materialsName }}</el-col>
          <el-col
            :span="6"
            style="font-size: 16px"
          >规格: {{ currentDialogData.materialsSpecifications }}</el-col>
          <el-col
            :span="6"
            style="font-size: 16px"
          >材质: {{ currentDialogData.materialsQuality }}</el-col>
        </el-row>
        <MTable
          v-if="visible"
          :columns="columns[dialogType]"
          :height="dialogTableHeight"
          :set-data-method="getInventoryHistory"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="sequenceNumber" slot-scope="{ row }">
            {{ row.sequenceNumber }}
          </div>
          <div slot="warehouseLocation" slot-scope="{ row }">
            {{ row.warehouseAreaName }} - {{ row.warehouseLocationName }}
          </div>
          <div slot="historyType" slot-scope="{ row }">
            {{ row.recordType ? outTypeName[row.type] : inTypeName[row.type] }}
          </div>
          <div slot="recordType" slot-scope="{ row }">
            {{ row.recordType ? "出库" : "入库" }}
          </div>
        </MTable>
      </div>
    </MDialog>

    <MDialog
      v-model="formVisible"
      :title="dialogTitle[dialogFormType]"
      @onOk="submitFormForm"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-position="top"
        style="padding-right: 30px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              v-if="dialogFormType === 'changePackage'"
              label="原包装规格"
              prop="oldPackage"
            >
              <el-input :value="form.oldPackage.name" disabled />
              <div style="color: #bfbfbf">
                换算系数：1 {{ form.oldPackage.containerUnitName }} =
                {{ form.oldPackage.conversionFactor }}
                {{ form.oldPackage.materialsUnit }}
              </div>
            </el-form-item>
            <el-form-item v-else label="移出库位" prop="oldPackage">
              <el-input
                :value="form.originPosition.warehouseLocationName"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="dialogFormType === 'changePackage'"
              label="新包装规格"
              prop="newPackage"
            >
              <el-select
                v-model="form.newPackageId"
                style="width: 100%"
                @change="newPackageChange"
              >
                <el-option
                  v-for="item in formOptions.containerClassList"
                  :key="item.id"
                  :label="item.name + '-' + item.specification"
                  :value="item.id"
                />
              </el-select>
              <div v-if="form.newPackageId" style="color: #bfbfbf">
                换算系数：1 {{ form.newPackage.containerUnitName }} =
                {{ form.newPackage.conversionFactor }}
                {{ form.oldPackage.materialsUnit }}
              </div>
            </el-form-item>
            <el-form-item v-else label="移入库位" prop="aimPosition">
              <el-select v-model="form.aimPosition" style="width: 100%">
                <el-option
                  v-for="item in formOptions.positionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </MDialog>

    <PrintCode ref="printCode" />
    <TableDialog title="查看明细" :show.sync="spareVisible" :columns="spareColumns" :table-data="spareList" :calc-height="32">
      <el-row :gutter="24" style="padding-bottom: 15px">
        <el-col :span="8">散件数量：{{ currentRow.quantity }}</el-col>
        <el-col :span="8">散件锁定数量：{{ currentRow.lockingQuantity }}</el-col>
        <el-col :span="8">散件可用数量：{{ currentRow.availableQuantity }}</el-col>
      </el-row>
    </TableDialog>
  </div>
</template>

<script>
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import SearchForm from '@/components/SearchForm/SearchForm'
import PrintCode from '@/components/PrintCode/printCode'
import Split from '@/components/Split/Split'
import FunctionHeader from '@/components/FunctionHeader/index'
import {
  searchFormListAll,
  searchFormListDetail,
  searchFormListDetailBbelc
} from './form-list'
import {
  columnsAll,
  columnsDetail,
  columnsInventoryHistory,
  columnsOthers,
  columnsPackage,
  columnsMaterial,
  columnsBatch,
  spareColumns
} from './columns'
import api from '@/api/warehouse/inventory'
import attributeApi from '@/api/information/materials-attribute'
import systemParams from '@/api/sets/function/system-params'
import warehouseApi from '@/api/information/warehouse/model'
import ccApi from '@/api/information/warehouse/package'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { inType, outType } from '@/config/options.config'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import TableDialog from '@/components/TableDialog/TableDialog'

export default {
  name: 'Inventory',
  components: {
    NewPageOpen,
    MaterialsClass,
    Split,
    FunctionHeader,
    SearchForm,
    PrintCode,
    TableDialog
  },
  data() {
    return {
      api,
      total:0,
      spareColumns,
      searchFormListDetailBbelc,
      params: {
        radio: 'InventoryDetail'
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportInventoryDetail',
        searchTitle: {
          Inventory: '请输入物料编码/名称/规格',
          InventoryDetail: '请输入物料编码/名称/规格/批次码',
          Materials: '请输入物料编码/名称/规格/批次码',
          Batch: '请输入物料编码/名称/规格/批次码'
        },
        exportParams: {}
      },
      functionHeader2: {
        searchVal: ''
      },
      columns: {
        Inventory: columnsAll,
        InventoryDetail: columnsDetail,
        Materials: columnsMaterial,
        Batch: columnsBatch,
        viewPackageDetail: columnsPackage,
        viewOtherDetail: columnsOthers,
        viewInventoryHistory: columnsInventoryHistory
      },
      split: {
        offset: 15
      },
      searchFormList: {
        Inventory: searchFormListAll,
        InventoryDetail: searchFormListDetail
      },
      searchFormData: {
        Inventory: { isShowZero: 0 },
        InventoryDetail: {}
      },
      defaultSearchFormData: {
        Inventory: { isShowZero: 0, warehouseIds: [] },
        InventoryDetail: {}
      },
      searchFormatData: {
        Inventory: [],
        InventoryDetail: []
      },
      materialsClassId: '',
      formOptions: {
        materialsClassList: [],
        containerClassList: [],
        positionList: [],
        warehouseList: [],
        warehouseRelativeList: []
      },
      selectTableData: [],
      selectTableDataDialog: [],
      visible: false,
      formVisible: false,
      dialogType: '',
      dialogFormType: '',
      dialogTitle: {
        viewPackageDetail: '查看箱数明细',
        viewOtherDetail: '查看散数明细',
        changePackage: '替换包装',
        transformPosition: '批量转移库位',
        viewInventoryHistory: '查看库存流水'
      },
      currentDialogData: {},
      currentFormDialogData: {},
      packageDetailData: [],
      originPackageDetailData: [],
      form: {
        oldPackage: {},
        originPosition: {},
        newPackage: '',
        aimPosition: ''
      },
      defaultSearch: {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      },
      rules: {
        newPackage: [
          { required: true, message: '请选择新包装', trigger: 'blur' }
        ],
        aimPosition: [
          { required: true, message: '请选择移入库位', trigger: 'blur' }
        ]
      },
      inTypeName: {},
      outTypeName: {},
      checkNumberKey: 'currentQuantity',
      isUnit: null,
      spareVisible: false,
      spareList: [],
      currentRow: {}
    }
  },
  computed: {
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectTableData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    height() {
      const height = {
        InventoryDetail: 44,
        Inventory: 0,
        Materials: -55,
        Batch: -55
      }[this.params.radio]
      return this.$store.state.app.contentHeight - 235 - 95 - height
    },
    bbelcHeight() {
      return this.$store.state.app.contentHeight - 235 - 132
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 24
    },
    dialogTableHeight() {
      return this.$store.state.app.contentHeight - 235 - 50
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    warehouseCurId() {
      return this.functionHeader.exportParams.warehouseId
    }
  },
  watch: {
    warehouseCurId: {
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.$refs.searchForm.setForm({
            warehouseLocationId: ''
          })
          const data = await warehouseApi.getAllNodesByWarehouseId(newVal)
          if (this.memberCode !== 'bbelc') {
            this.searchFormList.InventoryDetail[3].children[0].options = data.filter(
              item => item.type === 2
            )
          }
        }
      },
      deep: true
    }
  },
  beforeMount() {
    this.setCheckNumberKey()
  },
  mounted() {
    if (this.memberCode === 'bbelc') {
      this.searchFormList.InventoryDetail = this.searchFormListDetailBbelc
    } else {
      this.getMaterialsTypeList()
    }
    this.getWareHouseList()
    inType.forEach(item => {
      this.inTypeName[item.id] = item.name
    })
    outType.forEach(item => {
      this.outTypeName[item.id] = item.name
    })
  },
  methods: {
    resetSelectData() {
      this.selectTableData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectTableData = this.selectTableData.filter((item) => item.selectable)
    },
    async viewDetails(row) {
      const res = await api.getWarehouseMaterialsDetails({
        materialsId: row.materialsId,
        batchNumber: row.batchNumber,
        sequenceNumber: row.sequenceNumber,
        warehouseId: row.warehouseId,
        warehouseLocationId: row.warehouseLocationId
      })
      if (res) {
        this.currentRow = res.loosePartsResultBean
        this.spareList = res.containerResultBeanList || []
        this.spareVisible = true
      } else {
        this.$message.info('暂无详细数据')
      }
    },
    async setCheckNumberKey() {
      const res = await systemParams.getSystemParamsList({ code: 'stockWarn' })
      if (res && res[0]) {
        switch (res[0].selectedItemIdList[0]) {
          case 5:
            this.checkNumberKey = 'currentQuantity'
            break
          case 6:
            this.checkNumberKey = 'availableQuantity'
            break
          default:
            break
        }
      }
    },
    async getWareHouseList() {
      const res = await warehouseApi.getRepositoryModelList(this.defaultSearch)
      if (res) {
        this.formOptions.warehouseList = res.filter(item => item.type === 0)
        this.formOptions.warehouseRelativeList = res
        if (this.memberCode === 'bbelc') {
          this.searchFormList.InventoryDetail[0].children[0].options = this.formOptions.warehouseList
        } else {
          this.searchFormList.InventoryDetail[2].children[0].options = this.formOptions.warehouseList
        }
        this.searchFormList.Inventory[0].children[0].options = this.formOptions.warehouseList.filter(
          item => item.type === 0
        )
      }
    },

    async getMaterialsTypeList() {
      const list = await attributeApi.getMaterialsTypeList(this.defaultSearch)
      if (list) {
        this.searchFormList.InventoryDetail[0].children[0].options = list
      }
    },

    async getContainerClassList(ids) {
      const res = await ccApi.getContainerClassListByMaterialsIds(ids)
      if (res) {
        this.formOptions.containerClassList = res
      }
      return res && res.length
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const searchCondition = {
        sort: order,
        materialsClassId: this.materialsClassId,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search])),
        ...this.searchFormData[this.params.radio],
        isShowZero: 0
      }
      this.functionHeader.exportParams = searchCondition
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    radioChange(label) {
      Object.assign(this.functionHeader, {
        exportName: {
          Inventory: 'exportInventory',
          InventoryDetail: 'exportInventoryDetail',
          Materials: 'exportMaterialsInventory',
          Batch: 'exportBatch'
        }[label]
      })
      this.selectTableData = []
      this.$refs.searchForm && this.$refs.searchForm.reset()
    },
    allMore(command) {
      const functions = {
        batchExport: () => {
          this.batchExportFront()
        },
        batchDelivery: () => {
          this.batchDeliveryFront()
        }
      }
      functions[command]()
    },
    async batchDeliveryFront() {
      if (this.params.radio === 'InventoryDetail') {
        const dataNew = []
        this.selectTableData.map(item => {
          const obj = {
            batchNumber: item.batchNumber,
            materialsId: item.materialsId,
            warehouseId: item.warehouseId,
            warehouseAreaId: item.warehouseAreaId,
            warehouseLocationId: item.warehouseLocationId,
            palletCode: item.palletCode
          }
          dataNew.push(obj)
        })
        const res = await api.deliveryInventory(dataNew)
        if (res) {
          this.$message.success('出库成功')
          this.$refs.mTable.setTableData()
        }
      }
    },
    // 批量导出
    batchExportFront() {
      let dataIds
      if (this.params.radio === 'InventoryDetail') {
        dataIds = this.selectTableData.map(item => item.key)
      } else {
        dataIds = this.selectTableData.map(item => item.exportWarehouseSign)
      }
      this.$refs.functionHeader.export({
        exportWarehouseSign: dataIds.join('^')
      })
    },
    setMaterialsClassId(id) {
      this.materialsClassId = id
      this.$refs.mTable.setTableData(true)
    },
    getOtherKey() {
      return this.checkNumberKey === 'currentQuantity'
        ? 'availableQuantity'
        : 'currentQuantity'
    },
    getUnits(row) {
      const newMaterialsUnitList = this._.cloneDeep(row.materialsUnitList)
      if (!row.currentMaterialsUnit) {
        row.currentMaterialsUnit = row.materialsUnit
      }
      if (newMaterialsUnitList) {
        newMaterialsUnitList.unshift({
          name: row.currentMaterialsUnit,
          id: row.currentMaterialsUnit,
          main: true
        })
      }
      return newMaterialsUnitList
    },
    currentUnitChange(id, row) {
      row.materialsUnit = id
      const current = row.materialsUnitList.find(item => item.name === id)
      if (!current) {
        row.availableQuantity2 = row.availableQuantity
        row.currentQuantity2 = row.currentQuantity
      } else {
        row.availableQuantity2 =
          row.availableQuantity / current.conversionFactor
        row.currentQuantity2 = row.currentQuantity / current.conversionFactor
      }
    },
    // 安全线判定
    // checkNumber(row) {
    //   let str = ''
    //   if (row[this.checkNumberKey] < row.lowerLimit) {
    //     str = `低于库存下限 ${row.lowerLimit}`
    //   }
    //   if (row[this.checkNumberKey] > row.upperLimit) {
    //     str = `高于库存上限 ${row.upperLimit}`
    //   }
    //   return str
    // },
    // 获取物料类型
    setMaterialsClass(res) {
      this.formOptions.materialsClassList = res
    },
    // 查询
    searchTable(data) {
      const searchObj = {}
      data.forEach(item => {
        searchObj[item.fieldName] = item.fieldValue
      })
      this.searchFormData[this.params.radio] = searchObj
      this.$refs.mTable.setTableData(true)
    },
    // 表格选择变化
    handleSelectionChangeDialog(data) {
      this.selectTableDataDialog = data
    },
    handleSelectionChange(data) {
      this.selectTableData = data
    },
    // 查看箱数明细或散数明细
    async viewNumberDetails(row, type, val) {
      this.isUnit = val
      let res
      if (type === 'viewPackageDetail') {
        res = await api.getPackageDetail({
          materialsId: row.materialsId,
          warehouseId: row.warehouseId
        })
      }
      if (type === 'viewOtherDetail') {
        res = await api.getScatterDetail({
          materialsId: row.materialsId,
          warehouseId: row.warehouseId
        })
      }
      if (res) {
        this.dialogType = type
        this.currentDialogData = row
        this.packageDetailData = res
        this.originPackageDetailData = this._.cloneDeep(this.packageDetailData)
        this.visible = true
      }
    },
    // 查看库存流水
    async getInventoryHistory(condition, callback) {
      const { page, order } = condition
      const searchVal =
        this.params.radio === 'Inventory'
          ? [
            {
              id: getUUid(),
              fieldName: 'warehouseId',
              fieldType: 'number',
              fieldValue: this.selectTableData[0].warehouseId,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'materialsCode',
              fieldType: 'string',
              fieldValue: this.selectTableData[0].materialsCode,
              operator: 'eq'
            }
          ]
          : [
            {
              id: getUUid(),
              fieldName: 'batchNumber',
              fieldType: 'string',
              fieldValue: this.selectTableData[0].batchNumber,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'warehouseLocationId',
              fieldType: 'number',
              fieldValue: this.selectTableData[0].warehouseLocationId,
              operator: 'eq'
            }
          ]
      if (
        this.params.radio === 'InventoryDetail' &&
        this.selectTableData[0].palletCode
      ) {
        searchVal.push({
          id: getUUid(),
          fieldName: 'palletCode',
          fieldType: 'string',
          fieldValue: this.selectTableData[0].palletCode,
          operator: 'eq'
        })
      }
      const res = await this.api.viewInventoryHistoryPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(searchVal))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async viewInventoryHistory() {
      this.dialogType = 'viewInventoryHistory'
      this.currentDialogData = this.selectTableData[0]
      this.visible = true
    },
    // 转移库位
    async transferLocation() {
      const first = this.selectTableDataDialog[0].warehouseLocationId
      const hasDiff = this.selectTableDataDialog.every(
        item => item.warehouseLocationId === first
      )
      if (hasDiff) {
        const res = await warehouseApi.getAllNodesByWarehouseId(
          this.currentDialogData.warehouseId
        )
        if (res) {
          this.formOptions.positionList = res.filter(
            item =>
              item.type === 2 &&
              item.id !== this.selectTableDataDialog[0].warehouseLocationId
          )
          if (!this.formOptions.positionList.length) {
            this.$message.info('暂无其他库位可供转移')
            return
          }
          this.dialogFormType = 'transformPosition'
          this.form.originPosition = this.selectTableDataDialog[0]
          this.formVisible = true
          return
        }
      }
      this.$message.info('请选择同一库位的包装箱')
    },
    // 更换包装
    async changePackage(row) {
      const hasOther = await this.getContainerClassList(
        this.currentDialogData.materialsId
      )
      if (!hasOther) {
        this.$message.info('暂无其他包装可供更换')
        return
      }
      this.dialogFormType = 'changePackage'
      this.form.oldPackage = {
        ...row,
        ...JSON.parse(row.containerInfo)
      }
      if (this.form.newPackageId) delete this.form.newPackageId
      this.formVisible = true
    },
    // 拆包
    async unpacking(row) {
      const res = await api.unPackageContainer({
        type: 1,
        code: row.containerCode
      })
      if (res) {
        this.viewNumberDetails(this.currentDialogData, this.dialogType)
        this.$refs.mTable.setTableData()
        this.$message.success('拆包成功')
      }
    },
    // 补打包装编码
    printPackageCode(row) {
      this.$refs.printCode.print(row.containerCode)
    },
    newPackageChange(id) {
      this.form.newPackage = this.formOptions.containerClassList.find(
        item => item.id === id
      )
    },
    // 查询箱数/散数明细
    searchFromPackage() {
      const val = this.functionHeader2.searchVal
      if (!val) {
        this.packageDetailData = this._.cloneDeep(this.originPackageDetailData)
        return
      }
      if (this.dialogType === 'viewPackageDetail') {
        this.packageDetailData = this.originPackageDetailData.filter(
          item =>
            item.containerCode.indexOf(val) !== -1 ||
            item.batchNumberList.indexOf(val) !== -1
        )
      }
      if (this.dialogType === 'viewOtherDetail') {
        this.packageDetailData = this.originPackageDetailData.filter(
          item => item.batchNumber.indexOf(val) !== -1
        )
      }
    },
    submitForm(callback) {
      this.visible = false
      callback()
    },
    submitFormForm(callback) {
      // 批量转移库位
      if (this.dialogFormType === 'transformPosition') {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            const postData = []
            this.selectTableDataDialog.forEach(item => {
              postData.push({
                type: this.dialogType === 'viewPackageDetail' ? 1 : 0,
                code:
                  this.dialogType === 'viewPackageDetail'
                    ? item.containerCode
                    : item.batchNumber,
                sourceAreaId: item.warehouseLocationId,
                areaId: this.form.aimPosition
              })
            })
            const res = await api.changeWarehouseLocation(postData)
            if (res) {
              this.formVisible = false
              this.viewNumberDetails(this.currentDialogData, this.dialogType)
              this.$message.success('库位转移成功')
            }
            callback()
          } else {
            callback()
          }
        })
      }
      // 更换包装
      if (this.dialogFormType === 'changePackage') {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            const res = await api.changeClassForContainer({
              fromContainerClassId: this.form.oldPackage.id,
              toContainerClassId: this.form.newPackage.id,
              code: this.form.oldPackage.containerCode
            })
            if (res) {
              this.formVisible = false
              this.viewNumberDetails(this.currentDialogData, this.dialogType)
              this.$message.success('更换包装成功')
            }
            callback()
          } else {
            callback()
          }
        })
      }
    },
    // 采购
    purchase() {},
    // 入库
    inWarehouse() {
      this.$router.push({
        name: 'warehouseOperate',
        query: { type: 'add', operateType: 0 }
      })
    },
    // 出库
    outWarehouse() {
      this.$router.push({
        name: 'warehouseOperate',
        query: { type: 'add', operateType: 1 }
      })
    },
    allOperate(val) {
      this.$router.push({
        name: 'warehouseOperate',
        query: { type: 'add', operateType: val }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-wrap {
  width: 100%;
  padding-top: 10px;

  .m-radio-group {
    margin-left: 10px;
  }

  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  a {
    color: rgb(96, 127, 255);
  }

  .icon-lowerLimit,
  .icon-upperLimit {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .icon-upperLimit {
    background: url("../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
  .icon-lowerLimit {
    transform: scaleY(-1);
    background: url("../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
}

div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #df6a6a;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before,
div.tooltip:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #df6a6a;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #df6a6a;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 1;
}
</style>
