import { purchaseApprovalStatus } from '@/config/options.config'

export const searchFormList = [
  {
    key: 'status',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: purchaseApprovalStatus
      }
    ]
  },
  {
    colSpan: 6,
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content2' },
    children: [
      {
        slots: ['materialsM']
      }
    ]
  },
  {
    key: 'confirmUser',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择处理人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择制单人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'confirmTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '处理开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '制单开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]
