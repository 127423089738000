var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inventory-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "InventoryDetail" } }, [
            _vm._v("库存明细")
          ]),
          _c("el-radio-button", { attrs: { label: "Inventory" } }, [
            _vm._v("仓库汇总")
          ]),
          _c("el-radio-button", { attrs: { label: "Materials" } }, [
            _vm._v("物料汇总")
          ]),
          _c("el-radio-button", { attrs: { label: "Batch" } }, [
            _vm._v("批次码汇总")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "Split",
            {
              attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
              on: {
                "update:offset": function($event) {
                  return _vm.$set(_vm.split, "offset", $event)
                }
              }
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("MaterialsClass", {
                    attrs: { view: true },
                    on: {
                      setMaterialsClass: _vm.setMaterialsClass,
                      nodeClick: _vm.setMaterialsClassId
                    }
                  })
                ],
                1
              ),
              _c("template", { slot: "right" }, [
                _c("div", { staticClass: "materials-info" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchFormList[_vm.params.radio],
                          expression: "searchFormList[params.radio]"
                        }
                      ]
                    },
                    [
                      _c("SearchForm", {
                        ref: "searchForm",
                        attrs: {
                          "form-list-extend":
                            _vm.searchFormList[_vm.params.radio],
                          "form-data": _vm.searchFormData[_vm.params.radio],
                          "default-form":
                            _vm.defaultSearchFormData[_vm.params.radio]
                        },
                        on: { search: _vm.searchTable }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom" },
                    [
                      _c(
                        "FunctionHeader",
                        {
                          ref: "functionHeader",
                          attrs: {
                            "search-title":
                              _vm.functionHeader.searchTitle[_vm.params.radio],
                            radio: _vm.params.radio,
                            "columns-key": _vm.columnsKey,
                            "export-name": _vm.functionHeader.exportName,
                            "export-params": _vm.functionHeader.exportParams,
                            "show-export": !_vm.selectTableData.length
                              ? true
                              : false,
                            columns: _vm.columns[_vm.params.radio]
                          },
                          on: {
                            search: function($event) {
                              return _vm.$refs.mTable.setTableData(true)
                            }
                          },
                          model: {
                            value: _vm.functionHeader.searchVal,
                            callback: function($$v) {
                              _vm.$set(_vm.functionHeader, "searchVal", $$v)
                            },
                            expression: "functionHeader.searchVal"
                          }
                        },
                        [
                          _vm.permission(
                            _vm.params.radio + "InventoryHistory"
                          ) && !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.inWarehouse }
                                },
                                [_vm._v(" 入库 ")]
                              )
                            : _vm._e(),
                          _vm.permission(
                            _vm.params.radio + "InventoryHistory"
                          ) && !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.outWarehouse }
                                },
                                [_vm._v(" 出库 ")]
                              )
                            : _vm._e(),
                          !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.allOperate(2)
                                    }
                                  }
                                },
                                [_vm._v(" 调拨 ")]
                              )
                            : _vm._e(),
                          !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.allOperate(3)
                                    }
                                  }
                                },
                                [_vm._v(" 移库 ")]
                              )
                            : _vm._e(),
                          !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.allOperate(4)
                                    }
                                  }
                                },
                                [_vm._v(" 仓库盘点 ")]
                              )
                            : _vm._e(),
                          !_vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.allOperate(5)
                                    }
                                  }
                                },
                                [_vm._v(" 物料盘点 ")]
                              )
                            : _vm._e(),
                          _vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.batchExportFront }
                                },
                                [_vm._v(" 批量导出 ")]
                              )
                            : _vm._e(),
                          _vm.selectTableData.length
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    display: "inline-block",
                                    "font-size": "12px",
                                    color: "#393D60",
                                    "line-height": "32px"
                                  }
                                },
                                [
                                  _vm._v(" 已选 "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#607FFF" } },
                                    [_vm._v(_vm._s(_vm.getSelectionDataLength))]
                                  ),
                                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                                ]
                              )
                            : _vm._e(),
                          _vm.selectTableData.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: { click: _vm.resetSelectData }
                                },
                                [_vm._v("清空")]
                              )
                            : _vm._e(),
                          _c("div", {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center"
                            },
                            attrs: { slot: "all" },
                            slot: "all"
                          })
                        ],
                        1
                      ),
                      _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          columns: _vm.columns[_vm.params.radio],
                          height: _vm.height,
                          "set-data-method": _vm.getTableData,
                          "columns-key": _vm.columnsKey
                        },
                        on: { "selection-change": _vm.handleSelectionChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: _vm.checkNumberKey,
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      display: "inline-flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { display: "inline-block" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row[_vm.checkNumberKey + "2"] ||
                                                row[_vm.checkNumberKey]
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    row.lowerLimit &&
                                    row[_vm.checkNumberKey] < row.lowerLimit
                                      ? _c("i", {
                                          staticClass: "icon-lowerLimit"
                                        })
                                      : _vm._e(),
                                    row.upperLimit &&
                                    row[_vm.checkNumberKey] > row.upperLimit
                                      ? _c("i", {
                                          staticClass: "icon-upperLimit"
                                        })
                                      : _vm._e()
                                  ]
                                )
                              }
                            },
                            {
                              key: "materialsCode",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("NewPageOpen", {
                                  attrs: {
                                    path: {
                                      name: "materialsDetail",
                                      query: {
                                        id: row.materialsId,
                                        type: "view"
                                      }
                                    },
                                    text: row.materialsCode
                                  }
                                })
                              }
                            },
                            {
                              key: "unitName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    !row.materialsUnitList
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.materialsUnit) +
                                              " "
                                          )
                                        ])
                                      : _c(
                                          "el-select",
                                          {
                                            attrs: { value: row.materialsUnit },
                                            on: {
                                              change: function(id) {
                                                _vm.currentUnitChange(id, row)
                                              }
                                            }
                                          },
                                          _vm._l(_vm.getUnits(row), function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: index,
                                                attrs: { value: item.name }
                                              },
                                              [
                                                item.main
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#df6a6a"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "sup",
                                                          {
                                                            staticStyle: {
                                                              color: "#df6a6a"
                                                            }
                                                          },
                                                          [_vm._v("*")]
                                                        )
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(_vm._s(item.name))
                                                    ])
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "warehouseName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(" " + _vm._s(row.warehouseName) + " ")
                                ])
                              }
                            },
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "lowerLimit",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.lowerLimit ? row.lowerLimit : "-"
                                      ) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "upperLimit",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.upperLimit ? row.upperLimit : "-"
                                      ) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "warehouseLocation",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.warehouseLocationName) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: _vm.getOtherKey(),
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row[_vm.getOtherKey() + "2"] ||
                                          row[_vm.getOtherKey()]
                                      ) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "otherNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.viewNumberDetails(
                                          row,
                                          "viewOtherDetail",
                                          1
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.scatterQuantity) + " "
                                    )
                                  ]
                                )
                              }
                            },
                            {
                              key: "packageNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.viewNumberDetails(
                                          row,
                                          "viewPackageDetail"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.containerQuantity) + " "
                                    )
                                  ]
                                )
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.viewDetails(row)
                                        }
                                      }
                                    },
                                    [_vm._v(" 查看明细 ")]
                                  )
                                ])
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.dialogTitle[_vm.dialogType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          ["viewOtherDetail", "viewPackageDetail"].indexOf(_vm.dialogType) !==
          -1
            ? _c(
                "div",
                { staticClass: "detail-wrapper" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "padding-bottom": "20px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 10 }
                        },
                        [
                          _vm._v(
                            "物料名称: " +
                              _vm._s(_vm.currentDialogData.materialsName)
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 10 }
                        },
                        [
                          _vm._v(
                            "物料规格: " +
                              _vm._s(
                                _vm.currentDialogData.materialsSpecifications
                              )
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 4 }
                        },
                        [
                          _vm._v(
                            "数量: " +
                              _vm._s(
                                _vm.dialogType === "viewPackageDetail"
                                  ? _vm.currentDialogData.containerQuantity
                                  : _vm.currentDialogData.scatterQuantity
                              ) +
                              " " +
                              _vm._s(
                                _vm.isUnit === 1
                                  ? _vm.currentDialogData.materialsUnit
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "FunctionHeader",
                    {
                      attrs: {
                        "search-title":
                          _vm.dialogType === "viewOtherDetail"
                            ? "请输入批次码"
                            : "请输入包装码/批次码",
                        "columns-setting": false
                      },
                      on: { search: _vm.searchFromPackage },
                      model: {
                        value: _vm.functionHeader2.searchVal,
                        callback: function($$v) {
                          _vm.$set(_vm.functionHeader2, "searchVal", $$v)
                        },
                        expression: "functionHeader2.searchVal"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: !_vm.selectTableDataDialog.length
                          },
                          on: { click: _vm.transferLocation }
                        },
                        [_vm._v(" 库存转移 ")]
                      )
                    ],
                    1
                  ),
                  _c("MTable", {
                    attrs: {
                      "show-page": false,
                      data: _vm.packageDetailData,
                      columns: _vm.columns[_vm.dialogType],
                      "columns-setting": false,
                      height: _vm.dialogTableHeight
                    },
                    on: { "selection-change": _vm.handleSelectionChangeDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "warehouseLocation",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                " " +
                                  _vm._s(row.warehouseAreaName) +
                                  " - " +
                                  _vm._s(row.warehouseLocationName) +
                                  " "
                              )
                            ])
                          }
                        },
                        {
                          key: "container",
                          fn: function(ref) {
                            var row = ref.row
                            return JSON.parse(row.containerInfo)
                              ? _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        JSON.parse(row.containerInfo).name
                                      ) +
                                      " （" +
                                      _vm._s(
                                        JSON.parse(row.containerInfo)
                                          .specification
                                      ) +
                                      "） "
                                  )
                                ])
                              : _vm._e()
                          }
                        },
                        {
                          key: "batchNumberList",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.batchNumberList &&
                                      row.batchNumberList.join("，")
                                  ) +
                                  " "
                              )
                            ])
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changePackage(
                                          row,
                                          "changePackage"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("更换包装")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.unpacking(row)
                                      }
                                    }
                                  },
                                  [_vm._v("拆包")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.printPackageCode(row)
                                      }
                                    }
                                  },
                                  [_vm._v("补打包装箱码")]
                                )
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          ["viewInventoryHistory"].indexOf(_vm.dialogType) !== -1
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "padding-bottom": "20px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 6 }
                        },
                        [
                          _vm._v(
                            "仓库名称: " +
                              _vm._s(_vm.currentDialogData.warehouseName)
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 6 }
                        },
                        [
                          _vm._v(
                            "物料名称: " +
                              _vm._s(_vm.currentDialogData.materialsName)
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 6 }
                        },
                        [
                          _vm._v(
                            "规格: " +
                              _vm._s(
                                _vm.currentDialogData.materialsSpecifications
                              )
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "16px" },
                          attrs: { span: 6 }
                        },
                        [
                          _vm._v(
                            "材质: " +
                              _vm._s(_vm.currentDialogData.materialsQuality)
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.visible
                    ? _c("MTable", {
                        attrs: {
                          columns: _vm.columns[_vm.dialogType],
                          height: _vm.dialogTableHeight,
                          "set-data-method": _vm.getInventoryHistory
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "sequenceNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(" " + _vm._s(row.sequenceNumber) + " ")
                                ])
                              }
                            },
                            {
                              key: "warehouseLocation",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.warehouseAreaName) +
                                      " - " +
                                      _vm._s(row.warehouseLocationName) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "historyType",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.recordType
                                          ? _vm.outTypeName[row.type]
                                          : _vm.inTypeName[row.type]
                                      ) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "recordType",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.recordType ? "出库" : "入库") +
                                      " "
                                  )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          3836893457
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.dialogTitle[_vm.dialogFormType] },
          on: { onOk: _vm.submitFormForm },
          model: {
            value: _vm.formVisible,
            callback: function($$v) {
              _vm.formVisible = $$v
            },
            expression: "formVisible"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: { "padding-right": "30px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.dialogFormType === "changePackage"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "原包装规格", prop: "oldPackage" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.form.oldPackage.name,
                                  disabled: ""
                                }
                              }),
                              _c("div", { staticStyle: { color: "#bfbfbf" } }, [
                                _vm._v(
                                  " 换算系数：1 " +
                                    _vm._s(
                                      _vm.form.oldPackage.containerUnitName
                                    ) +
                                    " = " +
                                    _vm._s(
                                      _vm.form.oldPackage.conversionFactor
                                    ) +
                                    " " +
                                    _vm._s(_vm.form.oldPackage.materialsUnit) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "移出库位", prop: "oldPackage" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value:
                                    _vm.form.originPosition
                                      .warehouseLocationName,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.dialogFormType === "changePackage"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "新包装规格", prop: "newPackage" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  on: { change: _vm.newPackageChange },
                                  model: {
                                    value: _vm.form.newPackageId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "newPackageId", $$v)
                                    },
                                    expression: "form.newPackageId"
                                  }
                                },
                                _vm._l(
                                  _vm.formOptions.containerClassList,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label:
                                          item.name + "-" + item.specification,
                                        value: item.id
                                      }
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm.form.newPackageId
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#bfbfbf" } },
                                    [
                                      _vm._v(
                                        " 换算系数：1 " +
                                          _vm._s(
                                            _vm.form.newPackage
                                              .containerUnitName
                                          ) +
                                          " = " +
                                          _vm._s(
                                            _vm.form.newPackage.conversionFactor
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.form.oldPackage.materialsUnit
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "移入库位", prop: "aimPosition" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  model: {
                                    value: _vm.form.aimPosition,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "aimPosition", $$v)
                                    },
                                    expression: "form.aimPosition"
                                  }
                                },
                                _vm._l(_vm.formOptions.positionList, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintCode", { ref: "printCode" }),
      _c(
        "TableDialog",
        {
          attrs: {
            title: "查看明细",
            show: _vm.spareVisible,
            columns: _vm.spareColumns,
            "table-data": _vm.spareList,
            "calc-height": 32
          },
          on: {
            "update:show": function($event) {
              _vm.spareVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "padding-bottom": "15px" },
              attrs: { gutter: 24 }
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("散件数量：" + _vm._s(_vm.currentRow.quantity))
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "散件锁定数量：" + _vm._s(_vm.currentRow.lockingQuantity)
                )
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "散件可用数量：" + _vm._s(_vm.currentRow.availableQuantity)
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }