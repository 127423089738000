var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px"
                }
              },
              [
                _c(
                  "el-row",
                  _vm._l(_vm.detailReturnBaseFields, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: item.name } }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                          ])
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c("FunctionHeader", {
                ref: "functionHeader",
                attrs: {
                  "search-title": "请输入物料名称/编号/规格",
                  tags: _vm.functionHeader.tags,
                  "export-name": _vm.functionHeader.exportName,
                  "export-params": _vm.functionHeader.exportParams
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.mTable.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              }),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.applyReturnColumn,
                  height: 410,
                  "set-data-method": _vm.getTableData,
                  "columns-setting": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "materialsType",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(" " + _vm._s(row.materialsType) + " ")
                      ])
                    }
                  },
                  {
                    key: "planOutputQuantity",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              min: 0,
                              max: row.maxLockQuantity,
                              "controls-position": "right",
                              step: 1
                            },
                            model: {
                              value: row.lockQuantity,
                              callback: function($$v) {
                                _vm.$set(row, "lockQuantity", $$v)
                              },
                              expression: "row.lockQuantity"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "planTime",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", [_vm._v(_vm._s(row.planTime.join(" 至 ")))])
                      ]
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.del($index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("返 回")
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType, confirmButtonText: "入库" },
          on: { onOk: _vm.addOrEditSubmit },
          model: {
            value: _vm.visiable,
            callback: function($$v) {
              _vm.visiable = $$v
            },
            expression: "visiable"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuilds",
              attrs: { "form-data": _vm.dataForAdd, "form-list": _vm.formList }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  },
                  attrs: { slot: "warehouseLocationName" },
                  slot: "warehouseLocationName"
                },
                [
                  [
                    _c(
                      "span",
                      { staticStyle: { color: "#f00", width: "10px" } },
                      [_vm._v("*")]
                    ),
                    _c("el-cascader", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        options: _vm.warehouseLocationList,
                        props: { value: "id", label: "name" }
                      },
                      model: {
                        value: _vm.kwId,
                        callback: function($$v) {
                          _vm.kwId = $$v
                        },
                        expression: "kwId"
                      }
                    })
                  ]
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }