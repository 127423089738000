<template>
  <div class="inventory-wrap">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'warehouseAssignList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>备料详情</span>
      </div>
      <div class="right">
        <el-button v-if="permission('Finish') && baseFormData.materialPreparationStatus !== 3" size="small" type="primary" :loading="buttonLoadings" @click="startFinish">
          完成
        </el-button>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-row class="view-list" :gutter="24">
          <el-col
            v-for="(item, index) in fixData(columnsAll)"
            :key="item.prop"
            :span="6"
          >
            <span v-if="index === 1">
              <span class="bot-ti">{{ item.label }}：</span>
              <span class="bot-ti">
                <div
                  v-if="getValue(item) || getValue(item) === 0"
                  :style="{ color: preparationStatus[getValue(item)].color ? preparationStatus[getValue(item)].color : 'rgb(74, 79, 118)', background: `${preparationStatus[getValue(item)].color ? preparationStatus[getValue(item)].color : 'rgb(74, 79, 118)'}20`}"
                  class="tag-priority"
                >
                  {{ preparationStatus[getValue(item)].name ? preparationStatus[getValue(item)].name : '待下发' }}
                </div>
              </span>
            </span>
            <span v-else-if="index === 9">
              <span class="bot-ti">{{ item.label }}：</span>
              <span class="bot-ti">
                <div
                  :style="{ color: getValue(item) || getValue(item) === 0 ? priorityStatus[getValue(item)].color : 'rgb(74, 79, 118)', background: `${getValue(item)|| getValue(item) === 0 ? priorityStatus[getValue(item)].color : 'rgb(74, 79, 118)'}20`}"
                  class="tag-priority"
                >
                  {{ getValue(item) || getValue(item) === 0 ? priorityStatus[getValue(item)].name : '' }}
                </div>
              </span>
            </span>
            <span v-else-if="index === 10">
              <span class="bot-ti">{{ item.label }}：</span>
              <span class="bot-ti">
                {{ +getValue(item) === 1 ? '按工序备料' : +getValue(item) === 0 ? '按工单备料' : '' }}
              </span>
            </span>
            <span v-else>
              <span class="bot-ti">{{ item.label }}：</span>
              <span class="bot-ti">{{ getValue(item) }}</span>
            </span>

          </el-col>
          <el-col
            :span="6"
          >
            <span class="bot-ti">关联工序：</span>
            <span class="bot-ti">{{ baseFormData.procedureName }}</span>
          </el-col>

        </el-row>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">物料信息</div>
      <div class="card-bot">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          :search-title="'物料编号/名称'"
          style="padding: 0 0 10px 0"
          @search="$refs.mTables.setTableData(true)"
        />
        <MTable
          ref="mTables"
          :columns="columnsDetail"
          height="400"
          :set-data-method="getTableData"
          style="width: 99.5%"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        </MTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import api from '@/api/warehouse/warehouse-assign'
import { columnsAll, columnsDetail } from '../columns'
import { preparationStatus } from '@/config/options.config'
import FunctionHeader from '@/components/FunctionHeader/index'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'warehouseAssignDetail',
  components: { FunctionHeader },
  mixins: [],
  data() {
    return {
      preparationStatus,
      columnsAll,
      columnsDetail,
      params: {
        id: ''
      },
      baseFormData: {
        materialPreparationStatus: 3
      },
      functionHeader: {
        searchVal: ''
      },
      buttonLoadings: false
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    this.params = this.$route.query
    this.getDetail()
  },
  methods: {
    async startFinish() {
      this.$confirm('确认完成备料任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const ids = [this.params.id]
        const res = await api.batchWarehouseAssign(ids)
        if (res) {
          this.$message.success('完成成功')
          this.getDetail()
        }
      })
    },
    fixData(data) {
      return data.filter((item, index) => index > 0 && index < 12)
    },
    async getDetail() {
      const res = await api.getWarehouseAssignDetail(this.params.id)
      if (res) {
        this.baseFormData = res
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const val = [
        {
          id: getUUid(),
          fieldName: 'materialPreparationId',
          fieldType: 'number',
          fieldValue: +this.params.id,
          operator: 'eq'
        }
      ]
      // console.log('搜索=》', val)
      const searchCondition = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search, ...val]))
      }
      const res = await api.getWarehouseAssignDetailList({
        page: page.currentPage,
        limit: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
 .detail-card {
   margin-bottom: 20px;
 }
.card-bot{
  display:flex;
  flex-flow:row wrap;
  padding: 20px 22px 10px 22px!important;
  .bot-ti{
    // height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    display:inline-block;
    margin-bottom:16px;
  }
}
.procedure-detail-header{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img{
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
.inventory-wrap {
  width: 100%;
  padding: 10px;

  .bottom {
    width: 100%;
    height: 100%;
    padding-top: 10px;

    .materials-info {
      padding: 10px;
    }
  }

  a {
    color: rgb(96, 127, 255);
  }
}

div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #DF6A6A;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 1;
}

</style>
