var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inventory-wrap" }, [
    _c("div", { staticClass: "procedure-detail-header" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "warehouseAssignList" })
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c("span", [_vm._v("备料详情")])
        ]
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.permission("Finish") &&
          _vm.baseFormData.materialPreparationStatus !== 3
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.buttonLoadings
                  },
                  on: { click: _vm.startFinish }
                },
                [_vm._v(" 完成 ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _c(
            "el-row",
            { staticClass: "view-list", attrs: { gutter: 24 } },
            [
              _vm._l(_vm.fixData(_vm.columnsAll), function(item, index) {
                return _c("el-col", { key: item.prop, attrs: { span: 6 } }, [
                  index === 1
                    ? _c("span", [
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm.getValue(item) || _vm.getValue(item) === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "tag-priority",
                                  style: {
                                    color: _vm.preparationStatus[
                                      _vm.getValue(item)
                                    ].color
                                      ? _vm.preparationStatus[
                                          _vm.getValue(item)
                                        ].color
                                      : "rgb(74, 79, 118)",
                                    background:
                                      (_vm.preparationStatus[_vm.getValue(item)]
                                        .color
                                        ? _vm.preparationStatus[
                                            _vm.getValue(item)
                                          ].color
                                        : "rgb(74, 79, 118)") + "20"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.preparationStatus[
                                          _vm.getValue(item)
                                        ].name
                                          ? _vm.preparationStatus[
                                              _vm.getValue(item)
                                            ].name
                                          : "待下发"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : index === 9
                    ? _c("span", [
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-ti" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tag-priority",
                              style: {
                                color:
                                  _vm.getValue(item) || _vm.getValue(item) === 0
                                    ? _vm.priorityStatus[_vm.getValue(item)]
                                        .color
                                    : "rgb(74, 79, 118)",
                                background:
                                  (_vm.getValue(item) ||
                                  _vm.getValue(item) === 0
                                    ? _vm.priorityStatus[_vm.getValue(item)]
                                        .color
                                    : "rgb(74, 79, 118)") + "20"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getValue(item) ||
                                      _vm.getValue(item) === 0
                                      ? _vm.priorityStatus[_vm.getValue(item)]
                                          .name
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    : index === 10
                    ? _c("span", [
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                +_vm.getValue(item) === 1
                                  ? "按工序备料"
                                  : +_vm.getValue(item) === 0
                                  ? "按工单备料"
                                  : ""
                              ) +
                              " "
                          )
                        ])
                      ])
                    : _c("span", [
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-ti" }, [
                          _vm._v(_vm._s(_vm.getValue(item)))
                        ])
                      ])
                ])
              }),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [_vm._v("关联工序：")]),
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(_vm._s(_vm.baseFormData.procedureName))
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("物料信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            staticStyle: { padding: "0 0 10px 0" },
            attrs: { "search-title": "物料编号/名称" },
            on: {
              search: function($event) {
                return _vm.$refs.mTables.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTables",
            staticStyle: { width: "99.5%" },
            attrs: {
              columns: _vm.columnsDetail,
              height: "400",
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }