var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MDialog",
        {
          attrs: { width: "70%", title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _vm.currentType === "add"
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openMaterials("inventory")
                    }
                  }
                },
                [_vm._v("从库存选择物料")]
              )
            : _vm._e(),
          _vm.currentType === "add"
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openMaterials("materials")
                    }
                  }
                },
                [_vm._v("添加物料")]
              )
            : _vm._e(),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.CURDColumns,
              height: _vm.height,
              data: _vm.purchaseData,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "quantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0.1,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.quantity,
                          callback: function($$v) {
                            _vm.$set(row, "quantity", $$v)
                          },
                          expression: "row.quantity"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "requiredTime",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择日期", type: "date" },
                        model: {
                          value: row.requiredTime,
                          callback: function($$v) {
                            _vm.$set(row, "requiredTime", $$v)
                          },
                          expression: "row.requiredTime"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "remark",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { type: "textarea" },
                        model: {
                          value: row.remark,
                          callback: function($$v) {
                            _vm.$set(row, "remark", $$v)
                          },
                          expression: "row.remark"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.deleteRow($index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("select-materials", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectMaterials,
          from: _vm.from
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }