
export const searchFormListAll = [
  {
    key: 'warehouseIds',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择仓库',
      multiple: true,
      collapseTags: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'type',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择库存水平'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'label',
          value: 'value'
        },
        options: [
          {
            value: 0,
            label: '低于库存下限'
          },
          {
            value: 1,
            label: '高于库存上限'
          }
        ]
      }
    ]
  }
  // {
  //   colSpan: 5,
  //   key: 'isShowZero',
  //   tagName: 'el-select',
  //   props: {
  //     placeholder: '请选择是否显示0库存'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'label',
  //         value: 'value'
  //       },
  //       options: [
  //         {
  //           value: 1,
  //           label: '显示0库存'
  //         },
  //         {
  //           value: 0,
  //           label: '不显示0库存'
  //         }
  //       ]
  //     }
  //   ]
  // }
]

export const searchFormListDetail = [
  {
    colSpan: 6,
    key: 'materialsTypeId',
    tagName: 'el-select',
    props: {
      placeholder: '请输入物料类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'sequenceNumber',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入序列号'
    },
    children: []
  },
  {
    key: 'warehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择仓库'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'warehouseLocationId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择库位'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
  // {
  //   colSpan: 6,
  //   key: 'materialsSpecifications',
  //   tagName: 'el-input',
  //   defaultValue: 1,
  //   attrs: {
  //     placeholder: '请输入规格型号'
  //   },
  //   children: []
  // },
  // {
  //   colSpan: 6,
  //   key: 'batchNumber',
  //   tagName: 'el-input',
  //   defaultValue: 1,
  //   attrs: {
  //     placeholder: '请输入批次码'
  //   },
  //   children: []
  // },
  // {
  //   key: 'warehouseId',
  //   tagName: 'el-select',
  //   colSpan: 5,
  //   props: {
  //     placeholder: '请选择仓库'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  // {
  //   colSpan: 5,
  //   key: 'palletCode',
  //   tagName: 'el-input',
  //   defaultValue: 1,
  //   attrs: {
  //     placeholder: '请输入托盘编码'
  //   },
  //   children: []
  // },
  // {
  //   colSpan: 5,
  //   key: 'warehouseLocationCode',
  //   tagName: 'el-input',
  //   defaultValue: 1,
  //   attrs: {
  //     placeholder: '请输入库位编码'
  //   },
  //   children: []
  // }
]

export const searchFormListDetailBbelc = [
  {
    key: 'warehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择仓库'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'warehouseLocationCode',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入库位编码'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'width',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入宽度'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'thickness',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入厚度'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'convertValue',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入米数'
    },
    children: []
  }
]

